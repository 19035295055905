import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { Projects } from './index'
import styled from 'styled-components'
import posed from 'react-pose'
import WorkItem from '../components/work-item'
import { withPreview } from 'gatsby-source-prismic'

export const FadingContent = posed.div({
  exiting: { opacity: 0 },
})

/*const WorkContent = styled.div`
  padding-top: 8rem;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-top: 20rem;
  }
`;*/

export const WorkProjects = styled(Projects)`
  padding-top: 8rem;
  padding-bottom: 2rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-top: 28rem;
  }
`

const WorkInner = ({ transitionStatus, work, projects }) => {
  return (
    <Layout transitionStatus={transitionStatus}>
      <SEO
        title={work.social_title || 'Work'}
        description={work.social_description}
        image={work.social_image}
      />

      <FadingContent pose={transitionStatus}>
        {/*<WorkContent></WorkContent>*/}
        <WorkProjects
          mt={[0, 5]} mb={[5, 8]}
          data-cursor
        >
          {projects.map(({ project }) => (
            <WorkItem
              key={`work-item-${project.document.id}`}
              project={project.document}
            />
          ))}
        </WorkProjects>
      </FadingContent>
    </Layout>
  )
}

const Work = ({ data }) => {
  const work = data.allPrismicWork.edges[0].node.data

  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <WorkInner
          transitionStatus={transitionStatus}
          work={work}
          projects={work.projects}
        />
      )}
    </TransitionState>
  )
}

export default withPreview(Work)

export const query = graphql`
  {
    allPrismicWork {
      edges {
        node {
          data {
            hero_background {
              url
            }
            hero_heading {
              text
            }
            hero_subheading {
              text
            }
            projects {
              project {
                document {
                  ... on PrismicProject {
                    id
                    uid
                    url
                    data {
                      feature_image {
                        url
                      }
                      tout_image {
                        url
                      }
                      tout_video {
                        url
                      }
                      tout_vimeo_video
                      name {
                        text
                      }
                      tagline {
                        text
                      }
                    }
                  }
                }
              }
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`
