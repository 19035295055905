import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Heading } from 'rebass'
import styled from 'styled-components'
/*import AspectRatioBox from '../components/aspect-ratio-box'*/

const ItemWrap = styled(TransitionLink)`
  display: block;
  position: relative;
  padding: 3.2rem 2.6rem;
  color: #fff;
  background-color: #fff;
  
  & + * {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 6.4rem 4.8rem 5.8rem 14.4vw;
    
    &:hover { 
      z-index: 100;
      
      span {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: opacity 0.24s ease-in-out, visibility 0s 0s, transform 0.28s ease-in-out;
      }
      
      div {
        opacity: 1;
        visibility: visible;
        transform: rotate(0deg) translateX(0) translateY(0);
        transition: opacity 0.33s ease-in-out, visibility 0s 0s, transform 0.3s ease-out;
      }
    }
  }
`

const ItemName = styled(Heading)`
  position: relative;
  font-weight: 380;
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 1.22;
  margin-bottom: 0.8rem;
  z-index: 10;
  mix-blend-mode: difference;
  max-width: 102.4rem;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 7.2rem;
    line-height: 1.06;
    margin-bottom: 1.4rem;
  }
`

const ItemTagline = styled.span`
  display: block;
  position: relative;
  color: #6d6d6d;
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: 460;
  z-index: 10;
  mix-blend-mode: difference;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-16px);
    transition: opacity 0.16s ease-in-out, transform 0.16s ease-in-out;
  }
`

/*const ItemImage = styled(AspectRatioBox)`
  position: absolute;
  width: 60vw;
  top: -15vh;
  left: 15vw;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: rotate(3deg) translateX(100px) translateY(8px);
  transition: opacity 0.08s ease-in-out, visibility 0s 0.12s, transform 0.12s ease-out;
  z-index: 1; 
`*/

const WorkItem = ({ project }) => {
  return (
    <ItemWrap style={{ textDecoration: 'none' }}
      to={project.url}
    >
      {project.data.name && <ItemName>{project.data.name.text}</ItemName>}
      {project.data.tagline && <ItemTagline>{project.data.tagline.text}</ItemTagline>}

      {/*{project.data.feature_image && (
        <ItemImage ratio={8 / 3}>
          <Image src={project.data.feature_image.url} alt={project.data.feature_image.alt}/>
        </ItemImage>
      )}*/}


    </ItemWrap>
  )
}

export default WorkItem
